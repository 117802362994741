.navContainer {
  border-bottom: 1px solid #ddd;
}

.contentContainer {
  align-items: center;
  justify-content: end;
}

.userContentCreator p {
  color: #b4b4b4;
  font-size: 13px;
  font-weight: 700;
}

.userContentCreator h5 {
  font-weight: 800;
  font-size: 19px;
}

.logout {
  font-weight: 700;
}
