.successIcon {
  width: 69px;
  height: 69px;
}

.contentStyle h1 {
  color: #1c75bc;
  margin-bottom: 30px;
}

.contentStyle p {
  width: 420px;
  font-size: 16px;
}
