.textContainer {
  display: flex;
  align-items: center;
}

.textColor {
  color: #1c75bc;
}

.createList {
  font-size: 14px;
}

.radioLabels {
  font-weight: 400;
  font-size: 16px;
}

.num {
  border-bottom: 1px solid #b4b4b4;
}

.num p {
  font-size: 15px;
  margin-bottom: 5px;
}

/* history component */
.details {
  font-size: 14px;
}
.details span {
  font-size: 14px;
  font-weight: 600;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.finished {
  background-color: #23cc2e;
}

.active {
  background-color: #1c75bc;
}

.failed {
  background-color: #ff0068;
}

/* scheduled campaigns */
.editDelete div {
  color: #1c75bc;
}
