.sectionsContainers {
  width: 100%;
}

.login-bg {
  background: #f1f1f1 0% 0% no-repeat padding-box;
  min-height: 120vh;
}

.bg {
  background: #f1f1f1 0% 0% no-repeat padding-box;
  min-height: 120vh;
  width: 77%;
}

.pages-container {
  width: 96%;
}

.section-container {
  min-height: 120vh;
}

h1 {
  font-size: 30px;
}

label {
  font-size: 18px;
  color: #535353;
  font-weight: 600;
}

span {
  font-size: 18px;
  color: #535353;
}

img {
  width: 100%;
}

a {
  color: #535353;
  text-decoration: none;
}

p {
  color: #535353;
}

.loading {
  width: 50%;
  margin: auto;
}

span {
  color: #535353;
}

.table {
  --bs-table-bg: #fff !important;
}

.pointer {
  cursor: pointer;
}

.createLoader {
  width: 25px;
}

.btnBG {
  background: transparent
    linear-gradient(247deg, #ff0068 0%, #7e249c 51%, #1c75bc 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 10px;
  color: #f1f1f1;
}

.search-bar .form-control {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.edit-modal .modal-content {
  background-color: #f1f1f1;
}

/* sideNav */

.mysidenav {
  background: #1c75bc !important;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
  .sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  .sidenav---navicon---3gCRo
  + .sidenav---navtext---1AE_f {
  color: #2e4151 !important;
  font-weight: 900 !important;
}

.sidenav---sidenav---_2tBP {
  position: fixed !important;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u
  > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u
  > .sidenav---navitem---9uL5T::after {
  opacity: 0.5 !important;
}

.sidenav---sidenav-subnav---1EN61 {
  color: #1c75bc;
}

.sidenav---sidenav-subnav---1EN61
  > .sidenav---sidenav-subnavitem---1cD47
  > .sidenav---navitem---9uL5T {
  color: #1c75bc !important;
  display: flex !important;
}

.sidenav---sidenav-subnav---1EN61
  > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y
  > .sidenav---navitem---9uL5T {
  color: #1c75bc !important;
}

.sidenav---sidenav-subnav---1EN61
  > .sidenav---sidenav-subnavitem---1cD47
  > .sidenav---navitem---9uL5T:hover {
  background: #1c75bc !important;
  color: white !important;
}

.sidenav---sidenav-subnav---1EN61
  > .sidenav---sidenav-subnavitem---1cD47:first-child {
  padding: 0 24px;
  line-height: 48px;
  border-bottom: 1px #ccc solid;
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  background: #1c75bc;
  margin: 0 !important;
  font-weight: 600 !important;
}
