.sideNavContainer {
  background: #1c75bc;
  margin-right: 13px;
}

/* sideNav */
.navContainer {
  background: #1c75bc;
  width: 64px;
}

.iconContainer {
  width: 25px;
  margin: auto;
}
