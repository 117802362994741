.createCards {
  width: 350px;
  height: 180px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b4b4b4;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.createCards:hover {
  border: 1px solid #1c75bc;
}

.createCards h5 {
  color: #1c75bc;
}

.createList {
  width: 35px;
}

.listContainer {
  color: #1c75bc;
}

.listContainer h4 {
  color: #1c75bc;
}
