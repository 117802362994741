.rechargeContainer {
  min-height: 100vh;
  width: 25%;
}

.rechargeContainer p {
  font-weight: 700;
  font-size: 15px;
}

.rechargeContainer h5 {
  color: #1c75bc;
  font-weight: 800;
  font-size: 17px;
}

.quotaDetails {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.quotaDetails span {
  font-size: 15px;
  font-weight: 700;
}
