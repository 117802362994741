.imgContainer {
  width: 422px;
  height: 191px;
}

.verticalLine {
  position: relative;
}

.verticalLine::before {
  content: "";
  background: #d6d6d6;
  position: absolute;
  left: -10px;
  height: 76%;
  width: 1px;
  top: 9%;
}

[dir="rtl"].verticalLine::after {
  content: "";
  background: #d6d6d6;
  position: absolute;
  right: 0;
  height: 76%;
  width: 1px;
  top: 9%;
}

.centerContent {
  height: 90vh;
  align-items: center;
}

.title {
  margin-top: 18%;
}

.formContainer {
  width: 75%;
  margin: auto;
}

.loader {
  width: 26px;
}
