.search {
  position: relative;
}

.search div {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 19px;
}

.refreshBtn {
  color: #1c75bc;
}

.refreshBtn div {
  width: 16px;
}
