.btnContainer {
  border-bottom: 1px solid #1c75bc;
}

.active {
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #1c75bc;
  border-bottom: 1px solid #f1f1f1;
  color: #1c75bc;
  height: 43px;
  width: 285px;
  font-weight: 600;
}

.btnControl {
  background: #1c75bc 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  color: #fff;
  border: none;
  height: 43px;
  width: 285px;
  font-weight: 600;
}
