.currentPackage {
  color: #1c75bc;
  font-weight: 700;
  font-size: 20px;
}

.container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #b4b4b4;
  border-radius: 10px;
}

.activeContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 3px solid #1c75bc;
  border-radius: 10px;
}

.activeContainer .main h4 {
  color: #1c75bc;
}

.activeContainer .main p {
  color: #1c75bc;
}

.main h4 {
  color: #535353;
  font-weight: 700;
}

.main p {
  color: #535353;
  font-size: 18px;
  font-weight: 600;
}

.content {
  font-size: 15px;
}

.price {
  font-size: 16px;
  font-weight: 800;
}
